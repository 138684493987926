body {
  margin: 0;
  
  font-family: 'Lato', sans-serif;
}
p {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
}
h1 {
  font-family: 'Lato', sans-serif;
  font-weight: 900;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
